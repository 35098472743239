import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import Question from '../../../components/features/Question'
import Video from '../../../components/features/Video'
import Info from '../../../components/features/Info'
import Steps from '../../../components/features/Steps'
import SEO from '../../../components/common/SEO';
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query BulletinsQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
    }

    const bannerProps = {
        title: 'Bulletins and Alerts',
        subtitle: 'Communication on a timely basis is a key component of any compliant organization. With Workhub, management can communicate with their workers effectively (over instant SMS / email) and keep track of the views a bulletin receives. Make bulletins part of compliance to ensure workers read updates from your safety team.',
        featureName: 'Bulletin',
        rightButtonText: 'Why Bulletin Software?',
        rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
        mockupImg: '/features/bulletins/device-mockup.png'
    }

    const questionProps = {
        question: 'Has your bulletin board become a piece of antique abstract art?',
        answer: 'Bulletin boards have never been the ideal method of communication with important notes being buried by the next classified ad. Worse yet, how can we assess who got the memo (or the sticky note)? There are many issues such as:',
        answerBulletList: [
            'No way to assure prompt readership',
            'Nearly impossible to audit who received and who read',
            'Hard to get feedback on messages',
            'Tough to classify or target teams / locations',
        ],
        imageSrc: '/features/sketch/bulletin-large.png',
        imgAlt: 'Cartoon of an old Bulletin board that is too full of Bulletins and Alerts'
    }

    const videoProps = {
        videoSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
        videoImage: '/features/bulletins/bulletins-thumbnail.jpg',
        videoStatement: (<h2><strong>Bulletins and Alerts</strong></h2>),
    }

    const infoProps = {
        infoList: [
            {
                mockupImage: '/features/bulletins/feature-large-admin-bulletins-1.jpg',
                infoTitle: 'Create Information Rich Bulletins with Attachments',
                infoSubtitle: 'Choose from 4 different types of bulletins to keep your messages categorized and compose your posts with rich text and styling, upload attachments and even have your workers leave comments to answer any questions or concerns they might have.',
                infoBullets: [
                    {
                        name: 'Notify by SMS:',
                        description: 'SMS (Text message) notifications assure direct and instant delivery. Workers can click the link in the text and read the bulletin right away.'
                    },
                    {
                        name: 'Email with Read Receipt:',
                        description: 'Emails alerts are wired up with automatic read receipts to assure all readership is tracked.',
                    },
                    {
                        name: 'Save Drafts:',
                        description: 'Not ready to send? Save it as a draft now and send later.',
                    }
                ],
            },
            {
                mockupImage: '/features/bulletins/feature-large-admin-bulletins-2.jpg',
                infoTitle: 'Track Compliance with Adjustable Review Times',
                infoSubtitle: 'Easily configure who will receive the bulletin and set how long you would like them to review the note before the status is changed. Set an Archive Date to configure how bulletins are handled for new hires or simply turn compliance off for less important updates.',
                infoBullets: [
                    {
                        name: 'Recipient Filtering:',
                        description: 'Filter recipients by their location and/or their position so that bulletins only go to relevant workers.'
                    },
                    {
                        name: 'Send Reminders:',
                        description: 'Readership down? Send a reminder to all workers that have not reviewed the bulletin to prompt more readership.',
                    },
                    {
                        name: 'Commenting:',
                        description: 'Workers can comment on bulletins and admins can approve the comment to make it public to all workers.',
                    }
                ],
            }
        ]
    }

    const stepsProps = {
        title: 'Notifying Workers is as Easy as 1, 2, 3',
        subtitle: 'Updating your workers on all devices/platforms and tracking readership has never been easier',
        stepList: [
            {
                title: 'Get the Alert via SMS / Email',
                subtitle: 'Workers are notified instantly to take prompt action by clicking the link (SMS) or reading the email',
                imageSrc: '/features/bulletins/bulletins-step-1-alert.jpg'
            },
            {
                title: 'Read the Bulletin',
                subtitle: 'Read and review the bulletin / attachments and your status is automatically updated',
                imageSrc: '/features/bulletins/bulletins-step-2-read.jpg'
            },
            {
                title: 'Gain Compliance',
                subtitle: 'Gain complete compliance across your organization with logged dates of review for all workers',
                imageSrc: '/features/bulletins/bulletins-step-3-compliance.jpg'
            }
        ]
    }

    const seo = {
        title: 'Workhub | Free Bulletin and Alerts Software',
        description: "Ensure that important communications are received by workers & gain safety compliance by implimenting our Bulletin & Alert Software. Filter recipients, notify by SMS, 'read' reciept enabled.",
        image: '/features/bulletins/bulletins-thumbnail.jpg',
        // pathname,
        // article
    }


    const featureIndex = [
        {
            title: "Notify",
            para: "Ensure workers are reading important correspondence by sending out bulletins and alerts though email and SMS. ",
        },
        {
            title: "Recipient Filtering",
            para: "Select the recipients by position and location to ensure the bulletins and alerts go to relevant workers. ",
        },
        {
            title: "Set a Review Clock",
            para: "Choose a length of time the bulletins and alerts must be reviewed for before they can be considered acknowledged.",
        },
        {
            title: "Read Receipts",
            para: "Read receipts allow admins to see who has reviewed the bulletins and who has not.",
        },
        {
            title: "Send Reminders",
            para: "Send a reminder to all workers that have not reviewed the bulletin to prompt more readership.",
        },
        {
            title: "Commenting",
            para: "Workers can comment on bulletins and admins can approve the comment to make it public to all workers.",
        },
    ]


    return (
        <Layout {...lightboxProps}>
            <SEO {...seo} {...data.site} />
            <Banner {...bannerProps} {...lightboxProps} />
            <Question {...questionProps} />
            <Video {...videoProps} {...lightboxProps} />
            <Info {...infoProps} />
            <Steps {...stepsProps} />

            <section className="feature-overview-container" style={{marginBottom: '60px'}}>
                <h2>Overview</h2>

                <div className="feature-overview-grid">
                    {featureIndex.map(item => (
                        <FeatureCards title={item.title} para={item.para} />
                    ))}
                </div>
            </section>

        </Layout>
    )
}